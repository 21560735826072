
//production
var admin = "https://8cxk8nt5g4.execute-api.ap-south-1.amazonaws.com/dev/api/";     //admin

var rider = "https://77rmhgxew9.execute-api.ap-south-1.amazonaws.com/dev/api/";     //rider    
var service = "https://iev1ltgkqg.execute-api.ap-south-1.amazonaws.com/dev/api/";     //service





var driver = "https://b8x89ujjg3.execute-api.ap-south-1.amazonaws.com/dev/api/";     //driver

var master = "https://c83kz102ok.execute-api.ap-south-1.amazonaws.com/dev/api/";

//development
var local = "http://localhost:3000/dev/api/";

// import Vue from 'vue'
//var querystring = require('querystring');
import axios, { Axios } from 'axios'
// import store from '../store'

import { authHeader } from '../helpers/auth-header';

let header = authHeader()
// var header={
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": localStorage.getItem("token")
// }};
export default {
    getLan(data) {
        return axios.post(admin + "user/getLanguage", JSON.stringify(data), header)
    },

    login(data) {
        return axios.post(admin + "user/userlogin", JSON.stringify(data), header)
    },
    vehiclelist(data) {
        return axios.post(admin + "vehicles/adminVehicleList", JSON.stringify(data), header)
    },


    uploadFile(data) {
        return axios.post(admin + "getSignedUrlsgb/getSignedURL", JSON.stringify(data), header)
    },

    sosAlertMaster(data) {
        return axios.post(admin + "user/adminuserNotification", JSON.stringify(data), header)
    },
    todaysTravelListDashboard(data) {//Data/subjectlist
        return axios.post(admin + "userRide/todaysTravelListDashboard", JSON.stringify(data), header)
    },
    adminUserProfile(data) {//Data/subjectlist
        return axios.post(admin + "user/adminUserProfile", JSON.stringify(data), header)
    },
    vehicleDriverList(data) {
        return axios.post(admin + "vehicles/vehicleDriverList", JSON.stringify(data), header)
    },
    searchDriverVehicleAdd(data) {
        return axios.post(admin + "user/searchDriverVehicleAdd", JSON.stringify(data), header)
    },
    searchGroupVehicleAdd(data) {
        return axios.post(admin + "user/searchGroupVehicleAdd", JSON.stringify(data), header)
    },
    addDriverVehicle(data) {
        return axios.post(admin + "vehicles/addDriverVehicle", JSON.stringify(data), header)
    },
    activateVehicleDriver(data) {
        return axios.post(admin + "userRide/activateVehicleDriver", JSON.stringify(data), header)
    },
    rideMaster(data) {
        return axios.post(admin + "user/userRideMaster", JSON.stringify(data), header)
    },
    deleteusermaster(data) {
        return axios.post(admin + "user/deleteUser", JSON.stringify(data), header)
    },
    adminsendpushnotification(data) {
        return axios.post(admin + "user/adminsendpushnotification", JSON.stringify(data), header)
    },
    superset_login() {
        return axios.post('http://3.110.177.178:8088/api/v1/security/login', JSON.stringify({
            "password": "admin",
            "provider": "db",
            "refresh": true,
            "username": "admin"
        }), header)
    },
    superset_api(data) {
        return axios.post(admin + "user/getsupersettoken", JSON.stringify(data), header);

    },
    group_list(data) {
        return axios.post(admin + "user/groupList", JSON.stringify(data), header);
    },
    delete_group_master(data) {
        return axios.post(admin + "user/deletegroupmaster", JSON.stringify(data), header)
    },
    geocodelats(data) {
        return axios.post(admin + "user/geocodelats", JSON.stringify(data), header);
    },
    geocodelocationdetails(data) {
        return axios.post(admin + "user/geocodelocationdetails", JSON.stringify(data), header);
    },
    addgroup(data) {
        return axios.post(admin + "user/addgroup", JSON.stringify(data), header)
    },
    editgroup(data) {
        return axios.post(admin + "user/edit_group", JSON.stringify(data), header)
    },
    groupUserListget(data) {
        return axios.post(admin + "vehicles/groupUserList", JSON.stringify(data), header)
    },
    groupVehicleListget(data) {
        return axios.post(admin + "vehicles/groupVehicleList", JSON.stringify(data), header)
    },
    addUsersToGroup(data) {
        return axios.post(admin + "vehicles/addUsersToGroup", JSON.stringify(data), header)
    },
    mappedVehcielGroup(data) {
        return axios.post(admin + "vehicles/mappedVehcielGroup", JSON.stringify(data), header)
    },
    unmappedVehcielGroup(data) {
        return axios.post(admin + "vehicles/unmappedVehcielGroup", JSON.stringify(data), header)
    },
    add_group_stops(data) {
        return axios.post(admin + "user/add_group_stops", JSON.stringify(data), header)
    },
    delete_group_stops(data) {
        return axios.post(admin + "user/delete_group_stops", JSON.stringify(data), header)
    },
    getMenuList(data) {
        return axios.post(admin + "user/getMenuList", JSON.stringify(data), header)
    },
    getRoleList(data) {
        return axios.post(admin + "user/getRoleList", JSON.stringify(data), header)
    },
    addrole(data) {
        return axios.post(admin + "user/addrole", JSON.stringify(data), header)
    },
    editrole(data) {
        return axios.post(admin + "user/editrole", JSON.stringify(data), header)
    },
    deleterolemaster(data) {
        return axios.post(admin + "user/deleterole", JSON.stringify(data), header)
    },
    getMenuListMaster(data) {
        return axios.post(admin + "user/getMenuListMaster", JSON.stringify(data), header)
    },
    addmenu(data) {
        return axios.post(admin + "user/addmenu", JSON.stringify(data), header)
    },
    editmenu(data) {
        return axios.post(admin + "user/editmenu", JSON.stringify(data), header)
    },
    deletemenumaster(data) {
        return axios.post(admin + "user/deletemenu", JSON.stringify(data), header)
    },
    saveRoleMenuMapping(data) {
        return axios.post(admin + "user/saveRoleMenuMapping", JSON.stringify(data), header)
    },
    user_rolelist(data) {
        return axios.post(admin + "user/user_rolelist", JSON.stringify(data), header)
    },
    addclient(data) {
        return axios.post(admin + "user/addclient", JSON.stringify(data), header)
    },
    editclient(data) {
        return axios.post(admin + "user/editclient", JSON.stringify(data), header)
    },
    edituser(data) {
        return axios.post(admin + "user/edituser", JSON.stringify(data), header)
    },
    regUser(data) {
        return axios.post(admin + "user/regUser", JSON.stringify(data), header)
    },
    clientdepartmentlist(data) {
        return axios.post(admin + "clientdepartment/clientdepartmentlist", JSON.stringify(data), header)
    },
    clientDepartmentAdd(data) {
        return axios.post(admin + "clientdepartment/clientDepartmentAdd", JSON.stringify(data), header)

    },
    clientDepartmentUpdate(data) {
        return axios.post(admin + "clientdepartment/clientDepartmentUpdate", JSON.stringify(data), header)

    },
    client_DepartmentDelete(data) {
        return axios.post(admin + "clientdepartment/clientDepartmentDelete", JSON.stringify(data), header)

    },
    groupList(data) {
        return axios.post(admin + "user/groupList", JSON.stringify(data), header)

    },
    clientDepartmentMappGroup(data) {
        return axios.post(admin + "clientdepartment/clientDepartmentMappGroup", JSON.stringify(data), header)

    },
    getclientist(data) {
        return axios.post(admin + "user/getclientist", JSON.stringify(data), header)
    },
    department_grouplist(data) {
        return axios.post(admin + "user/department_group_list", JSON.stringify(data), header)
    },

    editvehicle(data) {
        return axios.post(admin + "vehicles/vehicleEdit", JSON.stringify(data), header)
    },
    upload_to_AWS(data) {
        return axios.post(admin + "getSignedUrlsgb/getSignedURL", JSON.stringify(data), header);
    },
    vehicle_type_master(data) {
        return axios.post(admin + "vehicles/get_vehicle_type_master", JSON.stringify(data), header)
    },
    vehicle_list_by_client_id(data) {
        return axios.post(admin + "vehicles/vehicle_list_by_client_id", JSON.stringify(data), header)
    },
    searchhireVehicleAdd(data) {
        return axios.post(admin + "user/searchhireVehicleAdd", JSON.stringify(data), header)
    },


    uploadFile(data) {
        return axios.post(admin + "getSignedUrlsgb/getSignedURL", JSON.stringify(data), header)
    },

    HireVehicleList(data) {
        return axios.post(admin + "vehicles/HireVehicleList", JSON.stringify(data), header)
    },

    vehicles_group_list(data) {
        return axios.post(admin + "vehicles/vehicles_group_list", JSON.stringify(data), header)
    },

    unmap_vehicle_route(data) {
        return axios.post(admin + "vehicles/unmap_vehicle_route", JSON.stringify(data), header)
    },
    get_client_type_master(data) {
        return axios.post(admin + "vehicles/get_client_type_master", JSON.stringify(data), header)
    },
    clientDashboard(data) {
        return axios.post(admin + "user/clientDashboardData", JSON.stringify(data), header)
    },
    fmDashboardData(data) {
        return axios.post(admin + "user/fmDashboardData", JSON.stringify(data), header)
    },
    ServiceList(data) {
        return axios.post(admin + "vehicles/UserServiceList", JSON.stringify(data), header)
    },
    serviceRequestList(data) {
        return axios.post(admin + "user/serviceRequestList", JSON.stringify(data), header)
    },
    deleteservicedata(data) {
        return axios.post(admin + "vehicles/deleteservice", JSON.stringify(data), header)
    },
    UserServicereqList(data) {
        return axios.post(admin + "vehicles/UserServicerequestList", JSON.stringify(data), header)
    },
    get_RideData(data) {
        return axios.post(admin + "user/getRideDataLive", JSON.stringify(data), header)
    },
    bulkvehicleupload_save(data) {
        return axios.post(admin + "vehicles/bulkvehicleupload", JSON.stringify(data), header)
    },
    bulkuserupload_save(data) {
        return axios.post(admin + "user/bulkuserupload", JSON.stringify(data), header)
    },
    deletevehicle(data) {
        return axios.post(admin + "vehicles/vehicleDelete", JSON.stringify(data), header)
    },
    RoleGroupMasterList(data) {
        return axios.post(admin + "user/RoleGroupMasterList", JSON.stringify(data), header)
    },

    departmentVehicleListget(data) {
        return axios.post(admin + "vehicles/departmentVehicleList", JSON.stringify(data), header)
    },
    mappedVehcielDepartment(data) {
        return axios.post(admin + "vehicles/mappedVehcielDepartment", JSON.stringify(data), header)
    },

    //******************************************************************E ticket************************************************************ */
    TicketTypeMaster(data) {
        return axios.post(admin + "user/getTicketTypeMaster", JSON.stringify(data), header)
    },
    TicketCategoryMaster(data) {
        return axios.post(admin + "user/getTicketCategoryMaster", JSON.stringify(data), header)
    },
    TicketStatusMaster(data) {
        return axios.post(admin + "user/getTicketStatusMaster", JSON.stringify(data), header)
    },
    submit_TicketNewUser(data) {
        return axios.post(admin + "user/submitTicketNewUser", JSON.stringify(data), header)
    },
    reg_user(data) {
        return axios.post(admin + "user/regCustomerUser", JSON.stringify(data), header)
    },
    upload_to_AWS(data) {
        return axios.post(admin + "getSignedUrlsgb/getSignedURL", JSON.stringify(data), header);
    },
    ticketlist(data) {
        return axios.post(admin + "user/ticketlist", JSON.stringify(data), header)   //admin
    },

    dashboardticketlist(data) {
        return axios.post(admin + "user/dashboardticketlist", JSON.stringify(data), header)   //admin
    },
    submitTicket(data) {
        return axios.post(admin + "user/submitTicket", JSON.stringify(data), header)
    },
    userlist(data) {
        return axios.post(admin + "user/userList", JSON.stringify(data), header)
    },
    customerDashboardData(data) {
        return axios.post(admin + "user/customerDashboardData", JSON.stringify(data), header)
    },
    agentDashboardData(data) {
        return axios.post(admin + "user/agentDashboardData", JSON.stringify(data), header)
    },
    AdminDashboardData(data) {
        return axios.post(admin + "user/adminDashboardData", JSON.stringify(data), header)
    },
    ticketByCategoriesDashboard(data) {
        return axios.post(admin + "user/ticketByCategoriesDashboard", JSON.stringify(data), header)
    },
    passwordreset(data) {
        return axios.post(admin + "user/resetPassword", JSON.stringify(data), header)
    },
    updateUserProfile(data) {
        return axios.post(admin + "user/updateUserProfile", JSON.stringify(data), header)
    },
    updateUserStatus(data) {
        return axios.post(admin + "user/updateUserStatus", JSON.stringify(data), header)
    },
    updateTicketStatus(data) {
        return axios.post(admin + "user/updateTicketStatus", JSON.stringify(data), header)
    },
     async getticketDetails(data) {
        return  await axios.post(admin + "user/ticketDetails", JSON.stringify(data), header) //admin
    },
    EditTicket(data) {
        return axios.post(admin + "user/editTicket", JSON.stringify(data), header)
    },
    SendTicketComment(data) {
        return axios.post(admin + "user/postTicketComment", JSON.stringify(data), header)
    },
    assignAgent(data) {
        return axios.post(admin + "user/assignAgent", JSON.stringify(data), header)
    },
    agentListDropdown(data) {
        return axios.post(admin + "user/agentListDropdown", JSON.stringify(data), header)
    },
    adminAgentListMaster(data) {
        return axios.post(admin + "user/adminAgentListMaster", JSON.stringify(data), header)
    },
    categoryListmaster(data) {
        return axios.post(admin + "user/categoryListmaster", JSON.stringify(data), header)
    },
    categoryAddEdit(data) {
        return axios.post(admin + "user/categoryAddEdit", JSON.stringify(data), header)
    },
    ticketByAgentAdminDashboard(data) {
        return axios.post(admin + "user/ticketByAgentAdminDashboard", JSON.stringify(data), header)
    },
    ticketByDateDashboard(data) {
        return axios.post(admin + "user/ticketByDateDashboard", JSON.stringify(data), header)
    },
    deleteCategory(data) {
        return axios.post(admin + "user/deleteCategory", JSON.stringify(data), header)
    },
    deleteTicket(data) {
        return axios.post(admin + "user/deleteTicket", JSON.stringify(data), header)
    },
    customerList(data) {
        return axios.post(admin + "user/customerList", JSON.stringify(data), header)
    },
    getFaqMaster(data) {
        return axios.post(admin + "user/getFaqMaster", JSON.stringify(data), header)
    },
    addEditFaq(data) {
        return axios.post(admin + "user/addEditFaq", JSON.stringify(data), header)
    },
    deleteFaqMaster(data) {
        return axios.post(admin + "user/deleteFaqMaster", JSON.stringify(data), header)
    },
    getClientList(data) {
        return axios.post(admin + "user/getClientList", JSON.stringify(data), header)
    },
    updateClientProfile(data) {
        return axios.post(admin + "user/updateClientProfile", JSON.stringify(data), header)
    },
    addclient(data) {
        return axios.post(admin + "user/addclient", JSON.stringify(data), header)
    },
    deleteclient(data) {
        return axios.post(admin + "user/deleteclient", JSON.stringify(data), header)
    },
    userNotification(data) {
        return axios.post(admin + "user/userNotification", JSON.stringify(data), header)
    },
    read_notification(data) {
        return axios.post(admin + "user/read_notification", JSON.stringify(data), header)
    },
    getTicketSource(data) {
        return axios.post(admin + "user/getTicketSource", JSON.stringify(data), header)
    },

    //source master
    getSourceList(data) {
        return axios.post(master + "source/getSourceList", JSON.stringify(data), header)
    },
    updateSourceProfile(data) {
        return axios.post(master + "source/updateSourceProfile", JSON.stringify(data), header)
    },
    addSource(data) {
        return axios.post(master + "source/addSource", JSON.stringify(data), header)
    },
    deleteSource(data) {
        return axios.post(master + "source/deleteSource", JSON.stringify(data), header)
    },

    // type master
    getTicketTypeList(data) {
        return axios.post(master + "TicketType/getTicketTypeList", JSON.stringify(data), header)
    },
    addTicketType(data) {
        return axios.post(master + "TicketType/addTicketType", JSON.stringify(data), header)
    },
    updateTicketType(data) {
        return axios.post(master + "TicketType/updateTicketType", JSON.stringify(data), header)
    },
    deleteTicketType(data) {
        return axios.post(master + "TicketType/deleteTicketType", JSON.stringify(data), header)
    },

    // Status master
    getTicketStatusList(data) {
        return axios.post(master + "TicketStatus/getTicketStatusList", JSON.stringify(data), header)
    },
    addTicketStatus(data) {
        return axios.post(master + "TicketStatus/addTicketStatus", JSON.stringify(data), header)
    },
    updateStatus(data) {
        return axios.post(master + "TicketStatus/updateStatus", JSON.stringify(data), header)
    },
    deleteTicketStatus(data) {
        return axios.post(master + "TicketStatus/deleteTicketStatus", JSON.stringify(data), header)
    },
    deleteTicketComment(data) {
        return axios.post(admin + "user/deletecomment", JSON.stringify(data), header)
    },
    ticketByWardAdminDashboard(data) {
        return axios.post(admin + "user/ticketByWardAdminDashboard", JSON.stringify(data), header)   //admin
    },
    getPriorityList(data) {
        return axios.post(master + "source/getPriorityList", JSON.stringify(data), header)   //master
    },
    getPriorityList(data) {
        return axios.post(master + "source/getPriorityList", JSON.stringify(data), header)   //master
    },
    getWardList(data) {
        return axios.post(master + "source/getWardList", JSON.stringify(data), header)   //master
    },
    wardlist(data) {
      
        return axios.post(admin + "user/wardlist", JSON.stringify(data), header)   //admin
    },
    updatepriority(data) {
        return axios.post(master + "source/updatepriority", JSON.stringify(data), header)
    },
    deleteWard(data) {
        return axios.post(admin + "user/deleteWard", JSON.stringify(data), header)
    },
    updateward(data) {
        return axios.post(admin + "user/updateward", JSON.stringify(data), header)
    },
    updatepriority(data) {
        return axios.post(master + "source/updatepriority", JSON.stringify(data), header)
    },
    sendotp(data) {
        return axios.post(admin + "user/sendotp", JSON.stringify(data), header)
    },
    verifyOtp(data) {
        return axios.post(admin + "user/verifyOtp", JSON.stringify(data), header)
    },
    resetnewPassword(data) {
        return axios.post(admin + "user/resetnewPassword", JSON.stringify(data), header)
    },
    getTicketRoleStatusMapping(data) {
        return axios.post(admin + "user/getTicketRoleStatusMapping", JSON.stringify(data), header)
    },
    getTicketRejectionReason(data) {
        return axios.post(admin + "user/getTicketRejectionReason", JSON.stringify(data), header)
    },
    getAllDesignation(data) {
        return axios.post(admin + "user/getAllDesignation", JSON.stringify(data), header)
    },
    getAllEmploymentStatus(data) {
        return axios.post(admin + "user/getAllEmploymentStatus", JSON.stringify(data), header)
    },
    getAllTicketStatusGroup(data) {
        return axios.post(admin + "user/getAllTicketStatusGroup", JSON.stringify(data), header)
    },
    wardAddEdit(data) {
        return axios.post(admin + "user/wardAddEdit", JSON.stringify(data), header)
    },




}